import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "APAIzaSyDoxIyQlwOCCd8_Zlt4ZCNIdDy6fHGKeDcI_KEY",
  authDomain: "neerathon-a267e.firebaseapp.com",
  projectId: "neerathon-a267e",
  storageBucket: "neerathon-a267e.appspot.com",
  messagingSenderId: "647539139167",
  appId: "1:647539139167:web:14a8d5e74d175a7f63f1ea",
  measurementId: "G-MLRCW8Y6R6"
}

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
export {db}