import React, { useState } from "react";
import Quantity from "./Quantity";
import Summary from "./Summary";
import Form from "./Form";

const Bill = () => {

    const [state,setState]=useState({'a':0,'b':0,'c':0,"but":0})

    const add =(props)=>{
        switch(props){
            case 'a':{
                setState({
                    'b':state['b'],
                    'c':state['c'],
                    'a':state['a']+1
                    ,"but":0
                });
                break;
            }
            case 'b':{
                setState({
                    'b':state['b']+1,
                    'c':state['c'],
                    'a':state['a'],"but":0
                });
                break;
            }
            case 'c':{
                setState({
                    'b':state['b'],
                    'c':state['c']+1,
                    'a':state['a'],"but":0
                });
                break;
            }
        }
        
    }

    const remove =(props)=>{
       
        switch(props){
            case 'a':{
                setState({
                    'b':state['b'],
                    'c':state['c'],
                    'a':state['a']-1,"but":0
                });
                break;
            }
            case 'b':{
                setState({
                    'b':state['b']-1,
                    'c':state['c'],
                    'a':state['a'],"but":0
                });
                break;
            }
            case 'c':{
                setState({
                    'b':state['b'],
                    'c':state['c']-1,
                    'a':state['a'],"but":0
                });
                break;
            }
        }
        
    }

    const onclick = (props)=>{
        console.log('hello');
        setState({'b':state['b'],
        'c':state['c'],
        'a':state['a'],
        "but":1})
    }

    const detail=()=>{
        if(state['but']==0){
        return <div>
            <div className=" flex justify-center ">
                        <div className=" w-4/5 ">
                            <p className="text-2xl mb-1">3 KM</p>
                            <p className="text-sm line-through text-gray-400 inline">₹600</p>
                            <p className="text-md inline ml-2">₹510</p>
                            <p className="text-sm font-bold text-red-600 rounded-xl bg-red-100 inline p-1 ml-3">15% OFF</p>
                            <p className="text-sm m-1 mt-3"><svg className="mx-1 w-3 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M176 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h16V98.4C92.3 113.8 16 200 16 304c0 114.9 93.1 208 208 208s208-93.1 208-208c0-41.8-12.3-80.7-33.5-113.2l24.1-24.1c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L355.7 143c-28.1-23-62.2-38.8-99.7-44.6V64h16c17.7 0 32-14.3 32-32s-14.3-32-32-32H224 176zm72 192V320c0 13.3-10.7 24-24 24s-24-10.7-24-24V192c0-13.3 10.7-24 24-24s24 10.7 24 24z"/></svg>
                            Closing on:<span className="font-bold"> 19 Dec 2022, 06:00 AM IST</span>
                            </p>

                        </div>
                        <div className="w-1/5  ">
                        <Quantity 
                                    no={state['a']}
                                    add={add}
                                    id='a'
                                    remove={remove}
                                    />
                        </div>    
                    </div>
                    <hr className="border-b border-yellow-300 my-8 w-5/6 m-auto " />


                    <div className=" flex justify-center ">
                        <div className=" w-4/5 ">
                            <p className="text-2xl mb-1">5 KM</p>
                            <p className="text-sm line-through text-gray-400 inline">₹800</p>
                            <p className="text-md inline ml-2">₹680</p>
                            <p className="text-sm font-bold text-red-600 rounded-xl bg-red-100 inline p-1 ml-3">15% OFF</p>
                            <p className="text-sm m-1 mt-3"><svg className="mx-1 w-3 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M176 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h16V98.4C92.3 113.8 16 200 16 304c0 114.9 93.1 208 208 208s208-93.1 208-208c0-41.8-12.3-80.7-33.5-113.2l24.1-24.1c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L355.7 143c-28.1-23-62.2-38.8-99.7-44.6V64h16c17.7 0 32-14.3 32-32s-14.3-32-32-32H224 176zm72 192V320c0 13.3-10.7 24-24 24s-24-10.7-24-24V192c0-13.3 10.7-24 24-24s24 10.7 24 24z"/></svg>
                            Closing on:<span className="font-bold"> 19 Dec 2022, 06:00 AM IST</span>
                            </p>

                        </div>
                        <div className="w-1/5  ">
                        <Quantity 
                                    no={state['b']}
                                    add={add}
                                    id='b'
                                    remove={remove}
                                    />
                        </div>    
                    </div>
                    <hr className="border-b border-yellow-300 my-8 w-5/6 m-auto" />
                    <div className=" flex justify-center ">
                        
                        
                        <div className=" w-4/5 ">
                            <p className="text-2xl mb-1">10 KM</p>
                            <p className="text-sm line-through text-gray-400 inline">₹1000</p>
                            <p className="text-md inline ml-2">₹850</p>
                            <p className="text-sm font-bold text-red-600 rounded-xl bg-red-100 inline p-1 ml-3">15% OFF</p>
                            <p className="text-sm m-1 mt-3"><svg className="mx-1 w-3 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M176 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h16V98.4C92.3 113.8 16 200 16 304c0 114.9 93.1 208 208 208s208-93.1 208-208c0-41.8-12.3-80.7-33.5-113.2l24.1-24.1c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L355.7 143c-28.1-23-62.2-38.8-99.7-44.6V64h16c17.7 0 32-14.3 32-32s-14.3-32-32-32H224 176zm72 192V320c0 13.3-10.7 24-24 24s-24-10.7-24-24V192c0-13.3 10.7-24 24-24s24 10.7 24 24z"/></svg>
                            Closing on:<span className="font-bold"> 19 Dec 2022, 06:00 AM IST</span>
                            </p>

                        </div>
                        <div className="w-1/5  ">
                        <Quantity 
                                    no={state['c']}
                                    add={add}
                                    id='c'
                                    remove={remove}
                                    />
                        </div>    
                    </div>
        </div>
        }
        else{
            return (
                <Form 
                    set={state}

                />
            )
        }
    }



    return (
        <div className="mb-8">
            <h1 className="text-center text-3xl font-light mt-8 text-orange-500 text-3xl">Neerathon 2023</h1>
            <p className="text-center text-md font-light text-2xl mb-1">26 Feb 2023 | 5:00 AM IST Onwards | Amanora Fern Club</p>

            <div className="grid grid-cols-1 md:grid-cols-3  place-items-center">

                <div className=" w-5/6 mb-8 md:mb-1 md:col-span-2 shadow-lg hover:shadow-2xl p-4">
                    
                    {detail()}               
                    
                </div>
                <div className="w-5/6 shadow-lg hover:shadow-2xl ">
                   <Summary state={state}
                            onclick = {onclick}
                   />

                </div>
            </div>
        </div>
    )
}

export default Bill;
