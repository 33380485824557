import React, { useState } from "react";
import Countdown from 'react-countdown';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Marquee from "react-fast-marquee";


const Home = () => {
    const [nav, setNav] = useState(false);
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <span>You are good to go!</span>;
        } else {
            // Render a countdown
            return <div className="md:text-6xl text-2xl font-light grid grid-cols-4 text-center">
                <div className="bg-gray-200 md:p-4 p md:mx-2 mx-1 w-14 md:w-36">
                    <span>{days}</span>
                    <span className="block md:text-xl text-sm">Days</span>
                </div>
                <div className="bg-gray-200 md:p-4 p md:mx-2 mx-1 w-14 md:w-36 ">
                    <span>{hours}</span>
                    <span className="block md:text-xl text-sm">Hours</span>
                </div>
                <div className="bg-gray-200 md:p-4 p md:mx-2 mx-1 w-14 md:w-36">
                    <span>{minutes}</span>
                    <span className="block md:text-xl text-sm">Minutes</span>
                </div>
                <div className="bg-gray-200 md:p-4 p md:mx-2 mx-1 w-14 md:w-36">
                    <span>{seconds}</span>
                    <span className="block md:text-xl text-sm">seconds</span>
                </div>

            </div>;

        }
    };
    return (
          <div>
            <Marquee speed="40" className="md:text-4xl text-3xl font-normal my-8 mb-16 text-yellow-400">
            Results announced. Click Results!
            </Marquee>
            <div className="grid grid-cols-3 md:grid-cols-6 p-4 sticky w-full mb-4">
                <img className="w-28 col-span-1" src="./logo.jpeg" />
                <h1 className="text-right md:col-span-2 col-span-2 md:text-5xl text-3xl font-medium hover:font-semibold hover:underline decoration-yellow-300 underline-offset-8 text-blue-800 pt-8">RCPA NEERATHON</h1>



                <div className=" md:col-span-3 col-span-1 md:ml-12">
                    <nav className="flex items-center justify-between flex-wrap p-6">

                        <div className="block sm:hidden">
                            <button onClick={() => setNav(!nav)} className="flex items-center px-3 py-2 border rounded text-yellow-400 border-yellow-400 hover:text-white hover:bg-yellow-400 bg-white hover:border-yellow-400">
                                <svg className="fill-current  h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Home</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                            </button>
                        </div>
                        <div className="w-full block flex-grow  sm:w-auto">

                            <div className={`${nav ? "" : "hidden"} font-medium  sm:flex sm:items-center text-lg sm:text-xl sm:justify-center text-black`}>

                                <a href="#about" className={`hover:text-yellow-400 block mt-3 sm:inline-block ms:mt-0 mr-3`}>
                                    About
                                </a>
                                <a href="#gallery" className={`hover:text-yellow-400 block mt-3 sm:inline-block ms:mt-0 mr-3`}>
                                    Gallery
                                </a>
                                <a href="#partners" className={`hover:text-yellow-400 block mt-3 sm:inline-block ms:mt-0 mr-3`}>
                                    Partners
                                </a>
                                <a href="#contact" className={`hover:text-yellow-400 block mt-3 sm:inline-block ms:mt-0 mr-3`}>
                                    Contact
                                </a>
                                <a href="https://www.rcpaevents.com/Sponsor" target="_blank" className={`hover:text-yellow-400 block mt-3 sm:inline-block ms:mt-0 mr-3`}>
                                    Title Sponsor
                                </a>
                                <a href="https://www.rcpaevents.com/message">
                                    <div className={`$ w-52 invisible md:visible text-blue-800 fill-yellow-400 font-semibold hover:text-white hover:fill-white hover:bg-yellow-300 text-center border border-yellow-300 mx-auto  py-2 `}>
                                        <button className="text-2xl px-3  ">Results<svg className="w-3 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg></button>
                                    </div>
                                </a>
                            </div>
                            <a href="https://www.rcpaevents.com/message">
                                    <div className={`$ w-52 md:hidden mt-4 text-blue-800 fill-yellow-400 font-semibold hover:text-white hover:fill-white hover:bg-yellow-300 text-center border border-yellow-300 mx-auto  py-2 `}>
                                        <button className="text-2xl px-3  ">Results<svg className="w-3 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg></button>
                                    </div>
                                </a>
                        </div>
                    </nav>
                </div>
            </div>

            <div id="home">
            <div className="grid grid-cols-1 md:grid-cols-4 md:mx-16 mx-4  place-items-center">
                    <div className="md:col-span-4 w-full  pb-4">
                        <img className="w-full shadow hover:shadow-2xl" src="./gallery/announce.png?auto=compress,format&w=800&h=450" alt="Banner" />
                    </div>
            </div>

                <div className="border border-yellow-300 md:mx-16 mx-4 rounded-lg p-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-3  place-items-center ">
                        <div className="text-center">
                            <p className="text-3xl font-normal">Race Begins In</p>
                           
                        </div>
                        <div className="col-span-2">
                            <Countdown date={new Date("2023-02-26").getTime()} renderer={renderer} />

                        </div>

                    </div>
                    {/* <a href="./Booking">
                        <div className={`$ w-52 mt-6 text-yellow-400 fill-yellow-400 font-semibold hover:text-white hover:fill-white hover:bg-yellow-300 text-center border border-yellow-300 mx-auto  py-3 `}>
                            <button className="text-2xl px-4  ">Register Now<svg className="w-3 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg></button>
                        </div>
                    </a> */}
                </div>
            </div>
           

            {/* About  */}
            <div id="about">
                <div className="rounded-lg bg-white grid md:grid-cols-2 grid-cols-1 md:mx-16 mx-4 p-6 my-10 shadow-lg hover:shadow-2xl">
                    <div className="text-xl font-light">
                        <p className="md:text-5xl text-3xl font-normal my-8 mb-16 text-yellow-400">Run For Water Conservation</p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span><span className="font-semibold">Neerathon 2023</span> planned on 26th February, 2023 is the fifth edition of fundraising Marathon organised by <span className="font-semibold">RCPA(Rotary Club of Pune Amanora)</span>.</p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span>The whole event is dedicated to a host of Philanthropic causes, main being the <span className="font-semibold">water conservation</span>.</p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span>Route starts and finishes in <span className="font-semibold">Amanora Park Town</span>.</p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span>Every finisher shall be honored with <span className="font-semibold">Medal and E-Certificate.</span></p>
                    </div>
                    <div className="">
                        <img className="md:w-3/4 px-6 mb-6 mx-auto" src="./gallery/1.png" />
                        <img className="md:w-3/4 px-6 mx-auto " src="./gallery/2.png" />
                    </div>
                </div>


                <div className="shadow hover:shadow-xl p-8 my-8 mx-4 md:mx-16 bg-white">
                    <p className="my-6 mb-12 mx-auto text-center text-2xl font-normal">We are expecting 1500+ runners to participate under 3 main categories </p>
                    <div className=" grid grid-cols-1 md:grid-cols-3 gap-3  place-items-center">
                        <div className="shadow-lg hover:shadow-xl text-center md:w-4/5 w-5/6">
                            <p className="text-yellow-400 my-4 text-4xl ">3 km</p>
                            <p className="text-gray-500 text-lg">Only at <span className="font-semibold text-xl text-gray-700">₹600</span></p>
                            <p className="text-gray-500 font-light text-base my-2"> (Family & Fun Run)</p>
                            <p className="font-semibold text-lg text-gray-600">For Amazing Discount <br/>Click Register Now!! </p>

                        </div>
                        <div className="shadow-lg hover:shadow-xl text-center md:w-4/5 w-5/6">
                            <p className="text-yellow-400 my-4 text-4xl ">5 km</p>
                            <p className="text-gray-500 text-lg">Only at <span className="font-semibold text-xl text-gray-700">₹800</span></p>
                            <p className="text-gray-500 font-light text-base my-2"> (Timed)</p>
                            <p className="font-semibold text-lg text-gray-600">For Amazing Discount <br/>Click Register Now!! </p>

                        </div>
                        <div className="shadow-lg hover:shadow-xl text-center md:w-4/5 w-5/6">
                            <p className="text-yellow-400 my-4 text-4xl ">10 km</p>
                            <p className="text-gray-500 text-lg">Only at <span className="font-semibold text-xl text-gray-700">₹1000</span></p>
                            <p className="text-gray-500 font-light text-base my-2"> (Timed)</p>
                            <p className="font-semibold text-lg text-gray-600">For Amazing Discount <br/>Click Register Now!! </p>

                        </div>
                    </div>
                    <a href="https://www.rcpaevents.com/message">
                                    <div className={`$ w-64 mt-12  text-blue-800 fill-yellow-400 font-semibold hover:text-white hover:fill-white hover:bg-yellow-300 text-center border border-yellow-300 mx-auto  py-2 `}>
                                        <button className="text-3xl px-3  ">Results <svg className="w-3 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg></button>
                                    </div>
                                </a>
                </div>

                <div className=" grid grid-cols-1 md:grid-cols-2 grid-cols-1 gap-1 my-12 mx-4 md:mx-16 mt-12 place-items-center">
                    <div className="shadow-lg hover:shadow-2xl p-6 text-lg font-light  md:px-10 px-6 bg-white">
                        <p className="  text-yellow-400 text-3xl my-4 text-center font-normal">Run and Support the Causes</p>
                        <p><span className="text-3xl  text-yellow-400 mx-3">•</span>Basic Education and Literacy</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>Peacebuilding and Conflict Prevention</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>Disease Prevention and Treatment</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>Sanitation and Hygiene</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>Maternal and Child Health</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>Community economic development</p>
                    </div>
                    <div className="shadow-lg hover:shadow-2xl p-6 text-lg font-light md:px-10 px-6 bg-white">
                        <p className="  text-yellow-400 text-3xl text-center my-4 font-normal">What do runners get</p>
                        <p><span className="text-3xl  text-yellow-400 mx-3">•</span>Dri Fit T-Shirts</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>Finisher’s Medal</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>Customised Bib & Timing Chip</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>On-course Hydration Support</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>On-course Physio & Medical Support</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>Personalised E-timing Certificate</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>Healthy Breakfast</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>Rewards & Gifts (Only for Winners)</p>
                        <p><span className="text-3xl text-yellow-400 mx-3">•</span>Valued Event Experience</p>

                    </div>
                </div>
            </div>


            <div id="gallery">
                <div className="rounded-lg bg-white grid grid-cols-1 md:grid-cols-2 md:mx-16 mx-4 p-6 my-10 shadow-lg hover:shadow-2xl">
                    <div className="text-lg font-light p-2">
                        <p className="md:text-5xl text-3xl font-normal mb-6 text-yellow-400">Where are funds used ?</p>
                        <p className="my-4">The funds raised from Neerathon 2023 shall be used mainly on water projects and also on other projects in the avenues of literacy, economic development, medical treatments and sanitation etc.</p>
                        <span className="text-2xl block text-yellow-400 mx-3">➔ Water ATMs</span><p className=" pl-10 pb-3">Built at Wafeghar Village</p>
                        <span className="text-2xl block text-yellow-400 mx-3">➔ Water on Wheels</span><p className=" pl-10 pb-3">Makes life easy for villagers to carry water</p>
                        <span className="text-2xl block text-yellow-400 mx-3">➔ Water Purifiers</span><p className=" pl-10 pb-3">This helps an entire community get access to safe and clean drinking water.</p>
                        <span className="text-2xl block text-yellow-400 mx-3">➔ Other Projects</span><p className=" pl-10 pb-3">Lot of other projects based on literacy, medical diagnosis & treatments, sanitation are being done.</p>

                    </div>
                    <div className="items-center p-4 justify-center my-auto  ">
                        <Carousel axis="horizontal" autoPlay="true" autoFocus={true} infiniteLoop="true" interval={2500} showArrows={false} showThumbs={false} showIndicators={true}>
                            <div className="">
                                <img src="gallery/4.png" />
                            </div>
                            <div className="">
                                <img src="gallery/5.png" />
                            </div>
                            <div className="">
                                <img src="gallery/6.png" />
                            </div>
                            <div className="">
                                <img className="h-72" src="gallery/7.png" />
                            </div>
                        </Carousel>
                    </div>
                </div>

            </div>

            <div id="gallery">
                <div className="rounded-lg bg-white grid grid-cols-1 md:grid-cols-2 md:mx-16 mx-4 p-6 my-10 shadow-lg hover:shadow-2xl">
                    <div className="text-lg font-light p-2">
                        <p className="md:text-5xl text-3xl font-normal mb-4 text-yellow-400">Use of funds raised through Neerathon 2022</p>
                        <p className="my-4">Rotary Club of Pune Amanora (RCPA), along with Maersk Global Services CSR Team, inaugurated the Water ATM at the Pilosari village (in Raigad district).  The installation of the Water ATM was done in memory of (Late) Major Hemant Manjrekar, who gave his life for India in the 1971 War. The Water ATM installation was excuted on ground by the Rotarians along with the village Sarpanch, Shri Sudhir Kedari and leadership of Rtn. Vasant Manjrekar. (Youngest Volunteer in RCPA at 83+ yrs of Young Age)</p>
                        <p className="my-4">The fund for WATER was raised through our flagship program - NEERATHON 2022 ( Charity Run of 3 Km, 5 Km, and 10 Km ) in Pune hosted by Amanora Fern Club supported by Amanora Park Town Management. This year also RCPA is organising Neerathon to raise funds and install more Water ATM. Please visit www.rcpaevents.com for more information.</p>
                        
                    </div>
                    <div className="items-center p-4 justify-center my-auto  ">
                        <Carousel axis="horizontal" autoPlay="true" autoFocus={true} infiniteLoop="true" interval={3000} showArrows={false} showThumbs={false} showIndicators={true}>
                            <div className="">
                                <iframe width="420" height="315"
                                    src="https://www.youtube.com/embed/EB40-KasYD0">
                                </iframe>
                            </div>
                            <div className="">
                                <img src="gallery/9.png" />
                            </div>
                            <div className="">
                                <img src="gallery/10.png" />
                            </div>
                            <div className="">
                                <img src="gallery/11.png" />
                            </div>
                            {/* <div className="">
                                <img src="gallery/12.png" />
                            </div> */}
                            <div className="">
                                <img src="gallery/13.png" />
                            </div>
                        </Carousel>
                    </div>
                </div>

            </div>


            <div id="partners" className="shadow hover:shadow-xl p-4 px-8 pb-8 my-8 md:mx-16 mx-4 bg-white">
                
            <div className="hover:underline decoration-yellow-300 underline-offset-8  ">
                    <p className="mt-16 mb-4 mx-auto text-center text-3xl font-normal">Title Sponsor </p>
                        <div className="shadow-lg hover:shadow-2xl text-center w-4/5">
                            <img src="./fpartners/4.png" width="400" height="510"/>
                        </div>                      
            </div>

                <div className="hover:underline decoration-yellow-300 underline-offset-8  ">
                    <p className="mt-16 mb-4 mx-auto text-center text-3xl font-normal">Partners </p>
                    <div className="mb-10 grid grid-cols-4 md:grid-cols-4 gap-3  place-items-center">
                        <div className="shadow-lg hover:shadow-2xl text-center w-4/5">
                            <img src="./fpartners/3.png" />
                        </div>
                        
                        <div className="shadow-lg hover:shadow-2xl text-center w-4/5">
                            <img src="./fpartners/1.png" />
                        </div>
                        <div className="shadow-lg hover:shadow-2xl text-center w-4/5">
                            <img src="./fpartners/5.png" />
                        </div>
                        <div className="shadow-lg hover:shadow-2xl text-center w-4/5">
                            <img src="./epartners/8.png" />
                        </div>
                    </div>
                </div>

                <div className="hover:underline decoration-yellow-300 underline-offset-8  ">

                    <p className="mt-16 mb-4 mx-auto text-center text-3xl font-normal">Event Partners </p>
                    <div className=" grid grid-cols-2 md:grid-cols-4 gap-3  place-items-center">
                        <div className="shadow hover:shadow-2xl text-center w-4/5">
                            <img src="./epartners/9.png" />
                        </div>
                        <div className="shadow hover:shadow-2xl text-center w-4/5">
                            <img src="./epartners/1.png" />
                        </div>
                        <div className="shadow hover:shadow-2xl text-center w-4/5">
                            <img src="./epartners/2.png" />
                        </div>
                        <div className="shadow hover:shadow-2xl text-center w-4/5">
                            <img src="./epartners/3.png" />
                        </div>
                        <div className="shadow hover:shadow-2xl text-center w-4/5">
                            <img src="./epartners/4.png" />
                        </div>
                        <div className="shadow hover:shadow-2xl text-center w-4/5">
                            <img src="./epartners/5.png" />
                        </div>
                        <div className="shadow hover:shadow-2xl text-center w-4/5">
                            <img src="./epartners/6.png" />
                        </div>
                        <div className="shadow hover:shadow-2xl text-center w-4/5">
                            <img src="./epartners/7.png" />
                        </div>
                    </div>
                </div>

                <div className="hover:underline decoration-yellow-300 underline-offset-8  ">
                    <p className="mt-6 mb-4  mx-auto text-center text-3xl font-normal">Rotary Partners </p>
                    <div className="mb-10 grid grid-cols-2 md:grid-cols-6 gap-1  place-items-center">
                        <div className="shadow hover:shadow-2xl text-center w-4/5">
                            <img src="./rpartners/1.png" />
                        </div>
                        <div className="shadow hover:shadow-xl text-center w-4/5">
                            <img src="./rpartners/2.png" />
                        </div>
                        <div className="shadow hover:shadow-xl text-center w-4/5">
                            <img src="./rpartners/3.png" />
                        </div>
                        <div className="shadow hover:shadow-xl text-center w-4/5">
                            <img src="./rpartners/4.png" />
                        </div>
                        <div className="shadow hover:shadow-xl text-center w-4/5">
                            <img src="./rpartners/6.png" />
                        </div>
                    </div>
                </div>

            </div>




            <footer id="contact" class="text-center lg:text-left bg-amber-400 text-gray-600">
                <div class="mx-6 py-10 text-center md:text-left">
                    <div class="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <div class="">
                            <h6 class="
            uppercase
            font-semibold
            mb-4
            flex
            items-center
            justify-center
            md:justify-start
          ">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cubes"
                                    class="w-4 mr-3" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z">
                                    </path>
                                </svg>
                                Neerathon
                            </h6>
                            <p>
                                The whole event is dedicated to a host of Philanthropic causes,
                                main being the water conservation.
                            </p>
                        </div>
                        <div class="">
                            <h6 class="uppercase font-semibold mb-4 flex items-center justify-center md:justify-star ">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cubes"
                                    class="w-4 mr-3" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z">
                                    </path>
                                </svg>
                                Venue - Amanora Park Town
                            </h6>
                            <p>
                                Survey No 194, Near Sadesatranji Village Near Mundhwa Flyover, Behind Amanora Mall,
                                Hadapsar Kharadi Bypass, Hadapsar-411028.
                            </p>
                        </div>
                        <div class="md:ml-16">
                            <h6 class=" uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                USEFUL LINKS
                            </h6>
                            <p class="mb-4">
                                <a href="#home" class="text-gray-600">Register</a>
                            </p>
                            <p class="mb-4">
                                <a href="#about" class="text-gray-600">About</a>
                            </p>
                            <p class="mb-4">
                                <a href="#partners" class="text-gray-600">Partners</a>
                            </p>
                            <p class="mb-4">
                                <a href="#gallery" class="text-gray-600">Gallery</a>
                            </p>
                        </div>
                        <div class="">
                            <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                Contact
                            </h6>
                            <p class="flex items-center justify-center md:justify-start mb-4">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                    class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                +91 95031 04824 Abhishek
                            </p>
                            <p class="flex items-center justify-center md:justify-start mb-4">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                    class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                +91 99250 63730 Sujal
                            </p>
                            <p class="flex items-center justify-center md:justify-start mb-4">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                    class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                +91 99709 22574 Pramod
                            </p>
                            <p class="flex items-center justify-center md:justify-start mb-4">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                    class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                +91 86002 32510 Saraswati
                            </p>
                        </div>

                    </div>
                </div>
            </footer>


        </div>
    )

}

export default Home;

