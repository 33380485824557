import React from "react";

const Sponsor = () => {
    return (
        <div>
            <div id="">
                <div className="rounded-lg bg-white grid md:grid-cols-2 grid-cols-1 md:mx-16 mx-4 p-6 my-10 shadow-lg hover:shadow-2xl">
                    <div className="text-xl font-light">
                        <p className="md:text-5xl text-3xl font-normal my-8 mb-16 text-yellow-400">About Kalyani Group</p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span>Established in mid 1960s, the Kalyani Group is an Indian multi-national with advanced technology, engineering & manufacturing capability across critical sectors such as Engineering Steel, Automotive, Industrial, Renewable Energy, Defence, Urban Infrastructure and Specialty Chemicals. With end-to-end capability and transcontinental manufacturing footprints across India, USA and different part of Europe, the Group has an annual turnover of over $3.0 billion. </p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span>Guided by visionary leadership of Mr. B. N. Kalyani, Group Chairman, with strong emphasis on market leadership through technology and it's over 12,000 strong engineering global workforce, the group today is a market leader in all its respective business segments.</p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span>The Kalyani Group strives to be a world class organization and a leader in every aspect of its business. Innovation has been the driving force behind the Group and is applied across every aspect of the business. The Spirit of Innovation fuels the Group to aggressively grow businesses by accessing global markets, to deliver products and services of uncompromising quality and integrity, consistent with the Kalyani brand and image.</p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span>The group comprises of several listed and unlisted companies. Some of the listed companies of the group are Bharat Forge Limited, Automotive Axles Limited, Hikal Limited, Kalyani Steels Limited, BF Investments Limited, BF Utilities Limited and Kalyani Investments Company Limited. Among the unlisted companies, the major ones are Saarloha Advanced Materials Private Limited and Kalyani Techno Forge Limited.</p>
                        <p className="md:text-5xl text-3xl font-normal my-8 mb-16 text-yellow-400">Planet Positive Initiative by Kalyani Group</p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span>Being a responsible corporate citizen, the group has always believed in giving back to the society, contributing in making a difference in the lives of the people. Achieving Carbon Neutrality is one of the core visions of the group and therefore, the group has charted a clear roadmap to achieve carbon neutrality in its operations under its Planet Positive Initiative. </p>
                        <p className="md:text-5xl text-3xl font-normal my-8 mb-16 text-yellow-400">About Saarloha, India’s First Green Steel manufacturer</p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span>Saarloha Advanced Materials Private Ltd. (Saarloha) is part of Kalyani group having its plant located in Pune, Maharashtra having an annual capacity of 204,000 MT. It is a renowned leader of forging and engineering quality alloy steel used in manufacturing of critical components for passenger & commercial vehicles, two-wheelers, bearings, tractors & farm equipment, railways, energy, defense, aerospace, oil & gas etc.</p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span>Over the years, Saarloha has been continuously and extensively upgrading its technology and infrastructure and boasts of many state-of-the-art modern manufacturing and testing facilities. It is one of the most preferred suppliers of steel in domestic as well as export markets because of its clean steel manufacturing processes, Research & Development capabilities, Stringent Quality Controls, Precise Chemistry Controls and Manufacturing Flexibility.</p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span>Considering, global change in customer preferences, global warming scenarios, and rising demand from the customers to decarbonize steelmaking operations, Saarloha has been working to reduce its GHG emissions by continuously enhancing its energy efficiency, reducing wastages, increasing usage of environmentally friendly fuels, increasing recycling materials, usage of renewable energy and many more. These relentless efforts to decarbonize its operations have resulted in creating India’s First Green Steel brand – KALYANI FeRRESTA.</p>
                        <p className="md:text-5xl text-3xl font-normal my-8 mb-16 text-yellow-400">Kalyani FeRRESTATM – India’s First Green Steel</p>
                        <p className="my-4"><span className="text-3xl  text-yellow-400 mx-3">•</span>In line with the Government of India’s commitment towards reducing carbon emissions, Saarloha has launched a first-of-its-kind in India, green steel under the brand name ‘Kalyani FeRRESTATM’ (verified and certified by DNV) by the hands of Union Minister of Steel and Civil Aviation, Shri. Jyotiraditya Scindia in Dec-22.</p>
                    </div>
                    <div className="">
                        <img className="md:w-3/4 px-6 mb-6 mx-auto margin-top = 20px margin-bottom = 20px" src="./fpartners/4.png" />
                        <img className="md:w-3/4 px-6 mb-4 mx-auto margin-top = 20px margin-bottom = 20px" src="./gallery/driving_inno.png" />
                        <img className="md:w-2/3 px-6 mb-4 mx-auto margin-top = 20px margin-bottom = 20px" src="./gallery/saravo.png" />
                        <img className="md:w-3/4 px-6 mb-6 mx-auto margin-top = 20px margin-bottom = 20px" src="./gallery/Opening.png" />
                    </div>
                    
                </div>
            </div>


 



            <footer id="contact" class="text-center lg:text-left bg-amber-400 text-gray-600">
                <div class="mx-6 py-10 text-center md:text-left">
                    <div class="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <div class="">
                            <h6 class="
            uppercase
            font-semibold
            mb-4
            flex
            items-center
            justify-center
            md:justify-start
          ">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cubes"
                                    class="w-4 mr-3" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z">
                                    </path>
                                </svg>
                                Neerathon
                            </h6>
                            <p>
                                The whole event is dedicated to a host of Philanthropic causes,
                                main being the water conservation.
                            </p>
                        </div>
                        <div class="">
                            <h6 class="uppercase font-semibold mb-4 flex items-center justify-center md:justify-star ">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cubes"
                                    class="w-4 mr-3" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z">
                                    </path>
                                </svg>
                                Venue - Amanora Park Town
                            </h6>
                            <p>
                                Survey No 194, Near Sadesatranji Village Near Mundhwa Flyover, Behind Amanora Mall,
                                Hadapsar Kharadi Bypass, Hadapsar-411028.
                            </p>
                        </div>
                        <div class="md:ml-16">
                            <h6 class=" uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                USEFUL LINKS
                            </h6>
                            <p class="mb-4">
                                <a href="#home" class="text-gray-600">Register</a>
                            </p>
                            <p class="mb-4">
                                <a href="#about" class="text-gray-600">About</a>
                            </p>
                            <p class="mb-4">
                                <a href="#partners" class="text-gray-600">Partners</a>
                            </p>
                            <p class="mb-4">
                                <a href="#gallery" class="text-gray-600">Gallery</a>
                            </p>
                        </div>
                        <div class="">
                            <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                Contact
                            </h6>
                            <p class="flex items-center justify-center md:justify-start mb-4">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                    class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                +91 95031 04824 Abhishek
                            </p>
                            <p class="flex items-center justify-center md:justify-start mb-4">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                    class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                +91 99250 63730 Sujal
                            </p>
                            <p class="flex items-center justify-center md:justify-start mb-4">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                    class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                +91 99709 22574 Pramod
                            </p>
                            <p class="flex items-center justify-center md:justify-start mb-4">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                    class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                +91 86002 32510 Saraswati
                            </p>
                        </div>

                    </div>
                </div>
            </footer>


        </div>
    )

}

export default Sponsor;

