import React, { Component } from 'react';


const TermsAndConditions = () => {


  return (
    <div className=" grid grid-cols-1 md:grid-cols-1 grid-cols-1 gap-1 my-12 mx-4 mt-12 place-items-center">
      <div className="shadow-lg hover:shadow-2xl p-6 text-lg font-light px-10 bg-white">
        <p className="  text-yellow-400 text-3xl my-4 text-center font-normal">TERMS AND CONDITIONS</p>
        <p><span className="text-3xl  text-yellow-400 mx-3">•</span>Please choose the event category carefully based on your fitness level</p>
        <p><span className="text-3xl text-yellow-400 mx-3">•</span>Information sent to the participants on the email address registered with the organizers shall be deemed as received by the runners.</p>
        <p><span className="text-3xl text-yellow-400 mx-3">•</span>You take full responsibility for participating in the event and do not hold the organizer / event management agency/other allied entities responsible for any injury or accident.</p>
        <p><span className="text-3xl text-yellow-400 mx-3">•</span>You shall consult your physician and undergo a complete medical examination to assess your suitability to participate in the event.</p>
        <p><span className="text-3xl text-yellow-400 mx-3">•</span>You also assume all risks associated with participating in this event including, but not limited to, falls, contact with other participants, the effects of the weather, including high heat or humidity, traffic and the condition of the road, arson or terrorist threats and all other risks associated with a public event.</p>
        <p><span className="text-3xl text-yellow-400 mx-3">•</span>You agree that organizer shall not be liable for any loss, damage, illness or injury that might occur as a result of your participation in the event.</p>
        <p><span className="text-3xl text-yellow-400 mx-3">•</span>You agree to abide by the instructions provided by the organizers from time to time in the best interest of your health and event safety.</p>
        <p><span className="text-3xl text-yellow-400 mx-3">•</span>You also agree to stop running if instructed by the event organizers.</p>
        <p><span className="text-3xl text-yellow-400 mx-3">•</span>You confirm that your name and media recordings taken during your participation may be used to publicize the event.</p>
        <p><span className="text-3xl text-yellow-400 mx-3">•</span>You may acknowledge and agree that your personal information can be stored and used by organizer, event management company and other sponsors or any other company in connection with the organization, promotion and administration of the event and for the compilation of statistical information.</p>
        <p><span className="text-3xl text-yellow-400 mx-3">•</span>You confirm that, in the event of adverse weather conditions, major incidents or threats on the day, any of the force major or restriction by authority, the organizers reserve the right to stop/cancel/postpone the event. You understand that confirmed registrations and merchandise orders are non-refundable, non-transferable and cannot be modified. The organizers reserve the right to reject any application without providing reasons. Any amount collected from rejected applications alone will be refunded in full (excluding bank charges wherever applicable)</p>
        <p><span className="text-3xl text-yellow-400 mx-3">•</span>If for any reason you cannot turn up for bib collection or on race day, you would not be allowed to run and no refund of any form will be given.</p>
        <p><span className="text-3xl text-yellow-400 mx-3">•</span>If this registration is being made on behalf of a minor, you confirm that you are the parent/guardian of the child and that He/She has your permission to take part in the event. You further accept that all the above rules shall apply to him/her as if he were a major.We request total co-operation from runners in this regards.</p>
        
      </div>
    </div>

  );


}

export default TermsAndConditions;