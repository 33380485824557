import React from "react";

const Summary =({state,onclick})=>{

  

    if(state['a']==0 && state['b']==0 && state['c']==0){
        return (
            <div className="text-yellow-300 h-40 w-full mt-6 p-10 text-2xl text-center mb-4">
                No Items Added
            </div>
        )
    }

    const item=state['a']+state['b']+state['c'];
    const items = (item==1)? <span>1 item</span>:<span>{item} items</span>;
    const price =state['a']*600+state['b']*800+state['c']*1000;
    const discount =state['a']*90+state['b']*120+state['c']*150;

    return (
        
        <div className=" w-full m-2 p-2 mb-4">
            <p className="text-xl text-gray-600 mb-5 text-center">Summary</p>
            <div className="flex text-gray-500">
                <p className="w-3/4">Price ({items})</p>
                <p className="w-1/4 flex font-semibold text-gray-600">₹{price}</p>
            </div>
            <div className="flex text-green-600  text-sm">
                <p className="w-3/4">Discount (15%)</p>
                <p className="w-1/4 flex font-semibold">-₹{discount}</p>
            </div>
            <div className="flex text-yellow-400 mt-4 text-xl">
                <p className="w-3/4 font-semibold">Total Amount</p>
                <p className="w-1/4 flex font-semibold">₹{price*0.85}</p>
            </div>
            <div className={`${state['but']==1?'hidden':''} mt-4 text-yellow-400 fill-yellow-400 font-semibold hover:text-white hover:fill-white hover:bg-yellow-300 text-center border border-yellow-300 mx-5 p-1 my-2`}
                onClick={()=>onclick()}
        >
                <button  className="text-xl   ">Proceed <svg className="w-3 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></button>
                </div> 
        </div>
    )
}

export default Summary;