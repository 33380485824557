import React from "react";

const Quantity =({no,id,add,remove})=>{
    if(no==0){
        return <div onClick={()=>add(id)} className="mt border-2 w-24 h-9 border-yellow-300 hover:bg-yellow-300 text-yellow-300 hover:text-white flex justify-center">
                <button  className=" text-lg" >+ADD</button>
            </div>
    }else{
        return <div className="mt border-2 w-24 h-9 border-yellow-300  flex justify-center">
        <button onClick={()=>remove(id)} className="mr-1 text-yellow-300 text-2xl">-</button>
        <span className="p-1 text-xl">{no}</span>
        <button onClick={()=>add(id)} className="ml-1 text-yellow-300 text-2xl" >+</button>
    </div>
    }

}

export default Quantity;