import React, { useState, useEffect } from "react";
import TermsAndConditions from "./TnC";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { db } from './firebase';


const Form = ({ set }) => {

    const [state, setState] = useState({ name: "", email: "", no: "", emg: "", gender: "", size: "", all: [] });
    const [tnc, settnc] = useState(false);



    const ontnc = () => {
        const t = tnc;
        settnc(!t);
    }
    var i = 0;
    const save = async (e) => {
        e.preventDefault()
        console.log(e);
        var r;
        if (arr[i] == "a") {
            r = '3KM';
        } else if (arr[i] == "b") {
            r = '5KM';
        } else if (arr[i] == "c") {
            r = '10KM';
        }
        const data = {
            name: state.name,
            email: state.email,
            no: state.no,
            emg: state.emg,
            gender: state.gender,
            size: state.size,
            race: r
        }
        console.log(state);
        const array = state.all;
        array.push(data);
        setState({ all: array, name: "", email: "", no: "", emg: "", gender: "", size: "" })
        console.log(state);
        i++;
        try {
            await addDoc(collection(db, 'tasks'), {
                data
                //   created: Timestamp.now()
            })
        } catch (err) {
            alert(err)
        }
    }

    const arr = [];

    const show = () => {
        var item = set['a'];
        while (item != 0) {
            arr.push('a');
            item--;
        }
        item = set['b'];
        while (item != 0) {
            arr.push('b');
            item--;
        }
        item = set['c'];
        while (item != 0) {
            arr.push('c');
            item--;
        }



        return arr.map((single, index) => {
            var whichkm = () => {
                if (single == "a") {
                    return '3KM';
                } else if (single == "b") {
                    return '5KM';
                } else if (single == "c") {
                    return '10KM';
                }
            }

            return <div className="shadow-lg p-3 hover:shadow-xl my-4">
                <p className="my-2 text-xl  text-gray-700">Attendee {index + 1} Details for <span className="font-semibold">{whichkm()}</span> Race </p>

                <div className={` ${index == state.all.length ? "" : "hidden"}`}>
                    <form onSubmit={save}>
                        <span className="block text-gray-500 font-light">Name<span className="text-red-500">*</span></span>
                        <input required autocomplete="off" id="name" value={state.name} name="name" onChange={(e) => { setState({ name: e.target.value, email: state.email, no: state.no, emg: state.emg, gender: state.gender, size: state.size, all: state.all }) }} type="text" className="mb-4 border-b border-yellow-300 focus:outline-none font-light focus:border-b-2 focus:border-yellow-500 mt-1 w-4/5"></input>

                        <span className="block text-gray-500 font-light">Email<span className="text-red-500">*</span></span>
                        <input required autocomplete="off" id="email" value={state.email} name="email" onChange={(e) => { setState({ email: e.target.value, name: state.name, add: state.add, emg: state.emg, gender: state.gender, size: state.size, all: state.all }) }} type="email" className="mb-4 border-b border-yellow-300 focus:outline-none font-light focus:border-b-2 focus:border-yellow-500 mt-1 active:border-yellow-500 w-4/5"></input>

                        <span className="block text-gray-500 font-light">Phone no<span className="text-red-500">*</span></span>
                        <input required autocomplete="off" id="no" value={state.no} name="no" onChange={(e) => { setState({ no: e.target.value, name: state.name, emg: state.emg, gender: state.gender, size: state.size, email: state.email, all: state.all }) }} type="text" className="mb-4 border-b border-yellow-300 focus:outline-none font-light focus:border-b-2 focus:border-yellow-500 mt-1 active:border-yellow-500 w-4/5"></input>

                        <span className="block text-gray-500 font-light">Emergency Contact<span className="text-red-500">*</span></span>
                        <input required autocomplete="off" id="add" value={state.add} name="add" onChange={(e) => { setState({ emg: e.target.value, gender: state.gender, size: state.size, name: state.name, email: state.email, no: state.no, all: state.all }) }} type="text" className="mb-4 border-b border-yellow-300 focus:outline-none font-light focus:border-b-2 focus:border-yellow-500 mt-1 active:border-yellow-500 w-4/5"></input>

                        <span className="block text-gray-500 font-light my-3">Gender<span className="text-red-500">*</span></span>
                        <fieldset id="gender">
                            <input type="radio" value="Male" name="gender" onClick={() => { setState({ emg: state.emg, gender: "male", size: state.size, name: state.name, email: state.email, no: state.no, all: state.all }) }} /> Male
                            <input type="radio" value="Female" name="gender" className="ml-4" onClick={() => { setState({ emg: state.emg, gender: "female", size: state.size, name: state.name, email: state.email, no: state.no, all: state.all }) }} /> Female
                            <input type="radio" for="gender" value="Other" name="gender" className="ml-4" onClick={() => { setState({ emg: state.emg, gender: "other", size: state.size, name: state.name, email: state.email, no: state.no, all: state.all }) }} /> Other

                        </fieldset>

                        <span className="block text-gray-500 font-light mt-6 mb-3">T-Shirt Size<span className="text-red-500">*</span></span>
                        <fieldset id="shirt_size">
                            <input type="radio" for="shirt" value="Male" name="shirt_size" onClick={() => { setState({ emg: state.emg, gender: state.gender, size: "XS", name: state.name, email: state.email, no: state.no, all: state.all }) }} /> XS
                            <input type="radio" value="Female" name="shirt_size" className="ml-4" onClick={() => { setState({ emg: state.emg, gender: state.gender, size: "S", name: state.name, email: state.email, no: state.no, all: state.all }) }} /> S
                            <input type="radio" value="Other" name="shirt_size" className="ml-4" onClick={() => { setState({ emg: state.emg, gender: state.gender, size: "M", name: state.name, email: state.email, no: state.no, all: state.all }) }} /> M
                            <input type="radio" value="Male" name="shirt_size" className="ml-4" onClick={() => { setState({ emg: state.emg, gender: state.gender, size: "L", name: state.name, email: state.email, no: state.no, all: state.all }) }} /> L
                            <input type="radio" value="Female" name="shirt_size" className="ml-4" onClick={() => { setState({ emg: state.emg, gender: state.gender, size: "XL", name: state.name, email: state.email, no: state.no, all: state.all }) }} /> XL
                            <input type="radio" value="Other" name="shirt_size" className="ml-4" onClick={() => { setState({ emg: state.emg, gender: state.gender, size: "XXL", name: state.name, email: state.email, no: state.no, all: state.all }) }} /> XXL
                        </fieldset>




                        <button type="submit" className={`mx-auto block text-yellow-400 fill-yellow-400 font-semibold hover:text-white hover:fill-white hover:bg-yellow-300 text-center border border-yellow-300 mt-4 p-1 mb-3`}>Save and Next</button>
                    </form>


                </div>

            </div>
        })
    }

    // const payment = () => {
    //     var easebuzzCheckout = new EasebuzzCheckout("2PBP7IABZ", 'test')
    //     document.getElementById('ebz-checkout-btn').onclick = function (e) {
    //         const access_key = "test";
    //         const options = {
    //             access_key: access_key, // access key received via Initiate Payment
    //             onResponse: (response) => {
    //                 console.log(response);
    //             },
    //             theme: "#123456" // color hex
    //         }
    //         easebuzzCheckout.initiatePayment(options);
    //     }
    // }




    return (
        <div className="p-1">

            {show()}
            <div className='text-center mt-4'>
                <span className="block my-4 mt-8 text-sm"> <a href="./tnc" ><span className="text-yellow-400 hover:font-semibold hover:text-base">Terms and Conditions ➔</span></a></span>
                <input type="checkbox" value={tnc} onClick={() => ontnc()} /><span className='text-base font-medium ml-4'>I accept Terms and Conditions</span>
            </div>
            <div className={`${(tnc == false) ? 'hidden' : ''}`}>


                <div onClick={() => { save() }} type="" className={`${(state['all'].length == arr.length) ? '' : 'hidden'} w-1/3 mt-6 text-yellow-400 fill-yellow-400 font-semibold hover:text-white hover:fill-white hover:bg-yellow-300 text-center border border-yellow-300 mx-auto p-1 my-2 `}>
                    <button id="ebz-checkout-btn" className="text-xl   ">Proceed <svg className="w-3 inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg></button>

                </div>
            </div>

        </div>
    )
}

export default Form;

