import React from "react";
import Bill from "./Bill.js";
import Home from "./Home.js";
import TermsAndConditions from "./TnC.js";
import Sponsor from "./Sponsor.js";
import Message from "./Message.js"

const App = () => {

    const page = () => {
        switch (window.location.pathname) {
            case '/':
                return <Home />
                break;
            case '/Booking':
                return <Bill />;
                break;
            case '/Sponsor':
                    return <Sponsor />;
                    break;
            case '/tnc':
                return <TermsAndConditions />;
                break;
            case '/message':
                return <Message />;
                break;
        }
    }

    return (
        <div className="bg-gray-50">
            {page()}
        </div>
    )
}

export default App;