import React from "react";

const Message = () => {
    return (
        <div>
            <div id="">
                <div className="rounded-lg bg-white md:mx-16 mx-4 p-6 my-10 shadow-lg hover:shadow-2xl">
                <div className="">
                <p className="md:text-5xl text-3xl font-normal my-8 mb-16 text-yellow-400">Results</p>
                                <p className="md:text-5xl text-3xl font-normal my-8 mb-16 text-yellow-400">Photos : ( All race categories ): </p>
                                <iframe width="1020" height="715"
                                    src="https://new.splitsecondpix.com/events/neerathon-2023/">
                                </iframe>
                </div>    
                    <div className="">
                    
                                <p className="md:text-5xl text-3xl font-normal my-8 mb-16 text-yellow-400">Timing : ( only for 5 km and 10 Km ): </p>
                                <a className="md:text-5xl text-3xl font-normal my-8 mb-16 text-yellow-400" href="http://www.alpharacingsolution.com/event/result/68a0fb56-1bbf-48a5-aee0-e99682e263cc?noheader=true" target="_blank" className={`hover:text-yellow-400 block mt-3 sm:inline-block ms:mt-0 mr-3`}>
                                    Scores
                                </a>
                    </div> 
                          
                </div>
            </div>
           

            <footer id="contact" class="text-center lg:text-left bg-amber-400 text-gray-600">
                <div class="mx-6 py-10 text-center md:text-left">
                    <div class="grid grid-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                        <div class="">
                            <h6 class="
            uppercase
            font-semibold
            mb-4
            flex
            items-center
            justify-center
            md:justify-start
          ">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cubes"
                                    class="w-4 mr-3" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z">
                                    </path>
                                </svg>
                                Neerathon
                            </h6>
                            <p>
                                The whole event is dedicated to a host of Philanthropic causes,
                                main being the water conservation.
                            </p>
                        </div>
                        <div class="">
                            <h6 class="uppercase font-semibold mb-4 flex items-center justify-center md:justify-star ">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cubes"
                                    class="w-4 mr-3" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M488.6 250.2L392 214V105.5c0-15-9.3-28.4-23.4-33.7l-100-37.5c-8.1-3.1-17.1-3.1-25.3 0l-100 37.5c-14.1 5.3-23.4 18.7-23.4 33.7V214l-96.6 36.2C9.3 255.5 0 268.9 0 283.9V394c0 13.6 7.7 26.1 19.9 32.2l100 50c10.1 5.1 22.1 5.1 32.2 0l103.9-52 103.9 52c10.1 5.1 22.1 5.1 32.2 0l100-50c12.2-6.1 19.9-18.6 19.9-32.2V283.9c0-15-9.3-28.4-23.4-33.7zM358 214.8l-85 31.9v-68.2l85-37v73.3zM154 104.1l102-38.2 102 38.2v.6l-102 41.4-102-41.4v-.6zm84 291.1l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6zm240 112l-85 42.5v-79.1l85-38.8v75.4zm0-112l-102 41.4-102-41.4v-.6l102-38.2 102 38.2v.6z">
                                    </path>
                                </svg>
                                Venue - Amanora Park Town
                            </h6>
                            <p>
                                Survey No 194, Near Sadesatranji Village Near Mundhwa Flyover, Behind Amanora Mall,
                                Hadapsar Kharadi Bypass, Hadapsar-411028.
                            </p>
                        </div>
                        <div class="md:ml-16">
                            <h6 class=" uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                USEFUL LINKS
                            </h6>
                            <p class="mb-4">
                                <a href="#home" class="text-gray-600">Register</a>
                            </p>
                            <p class="mb-4">
                                <a href="#about" class="text-gray-600">About</a>
                            </p>
                            <p class="mb-4">
                                <a href="#partners" class="text-gray-600">Partners</a>
                            </p>
                            <p class="mb-4">
                                <a href="#gallery" class="text-gray-600">Gallery</a>
                            </p>
                        </div>
                        <div class="">
                            <h6 class="uppercase font-semibold mb-4 flex justify-center md:justify-start">
                                Contact
                            </h6>
                            <p class="flex items-center justify-center md:justify-start mb-4">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                    class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                +91 95031 04824 Abhishek
                            </p>
                            <p class="flex items-center justify-center md:justify-start mb-4">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                    class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                +91 99250 63730 Sujal
                            </p>
                            <p class="flex items-center justify-center md:justify-start mb-4">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                    class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                +91 99709 22574 Pramod
                            </p>
                            <p class="flex items-center justify-center md:justify-start mb-4">
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
                                    class="w-4 mr-4" role="img" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
                                    </path>
                                </svg>
                                +91 86002 32510 Saraswati
                            </p>
                        </div>

                    </div>
                </div>
            </footer>


        </div>
    )

}

export default Message;

